import React from "react";
import { Waypoint } from "react-waypoint";
import { TextContainer, Text } from "./styled-components";

export default function Scene({
  children,
  design,
  textContainerStyle,
  onEnter,
  onLeave,
  bottomOffset,
  topOffset,
  centered,
}) {
  return (
    <Waypoint
      onEnter={onEnter}
      onLeave={onLeave}
      topOffset={topOffset}
      bottomOffset={bottomOffset}
    >
      <div css={[{ height: "100vh" }, design]}>
        {centered ? (
          <Text>{children}</Text>
        ) : (
          <TextContainer
            css={{
              height: "100%",
              width: "350px",
              fontSize: "200%",
              backgroundColor: "rgba(10, 10, 10, 0.75)",
              marginLeft: "4rem",
              color: "white",
              lineHeight: "1.4",
              ...textContainerStyle,
            }}
          >
            {children}
          </TextContainer>
        )}
      </div>
    </Waypoint>
  );
}
