import React from "react";
import { Waypoint } from "react-waypoint";
import { TextContainer } from "./styled-components";

export default function FullscreenScene({
  children,
  design,
  textContainerStyle,
  onEnter,
  onLeave,
  bottomOffset,
  topOffset,
}) {
  return (
    <>
      <Waypoint
        onEnter={onEnter}
        onLeave={onLeave}
        bottomOffset={bottomOffset}
        topOffset={topOffset}
      >
        <div css={{ height: "100vh", ...design }}>
          <TextContainer
            css={{
              height: "100%",
              backgroundColor: "unset",
              maxWidth: "1200px",
              fontSize: "1.6rem",
              margin: "auto",
              color: `rgba(255,255,255)`,
              lineHeight: "1.6",
              ...textContainerStyle,
            }}
          >
            {children}
          </TextContainer>
        </div>
      </Waypoint>
    </>
  );
}
