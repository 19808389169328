import React from "react";
import { HorizontalBar } from "react-chartjs-2";

const data = {
  labels: ["Russian Forces", "Syrian Armed Forces", "Syrian or Russian Forces"],
  datasets: [
    {
      label: "# of attacks",
      data: [4, 5, 2],
      backgroundColor: [
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
        "rgba(217, 156, 127, 0.2)",
      ],
      borderColor: [
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
        "rgba(179, 58, 0, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const legendOpts = {
  labels: {
    fontColor: "rgb(255, 255, 255)",
  },
};

const options = {
  scales: {
    xAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: "white",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          fontColor: "white",
        },
      },
    ],
  },
};

const KafrzitaChart = () => (
  <div
    css={{
      position: "relative",
      zIndex: 3,

      marginTop: "2rem",
      marginBottom: "2rem",
    }}
  >
    <HorizontalBar
      data={data}
      options={options}
      legend={legendOpts}
      width={900}
      height={400}
    />
  </div>
);

export default KafrzitaChart;
