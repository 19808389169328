import React from "react";
import { Waypoint } from "react-waypoint";
import { TextContainer } from "./styled-components";

export default function IntroScene({
  children,
  design,
  textContainerStyle,
  onEnter,
  onLeave,
}) {
  return (
    <Waypoint onEnter={onEnter} onLeave={onLeave}>
      <div css={{ height: "100vh", ...design }}>
        <TextContainer css={{ ...textContainerStyle }}>
          {children}
        </TextContainer>
      </div>
    </Waypoint>
  );
}
