import styled from "@emotion/styled";

export const BL = styled.br({
  marginBottom: "1.5rem !important",
});

export const Text = styled.div({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  textAlign: "center",
  fontSize: "200%",
  color: "white",
});

export const TextContainer = styled.div({
  backgroundColor: "rgba(10, 10, 10, 0.53)",
  position: "relative",
  maxWidth: "1000px",
  margin: "auto",
  padding: "2rem",
  paddingTop: "2rem",
  paddingBottom: "2rem",
});
